<template>
  <div class="new-newspaper">
    <a-steps v-model:current="current">
      <a-step title="第一步" description="基本信息填写" />
      <a-step title="第二步" description="报馆资料提交" :disabled="current>1?false:true" />
      <a-step title="第三步" description="缴费明细" :disabled="current>3?false:true" />
    </a-steps>
    <div>
      <div v-show="current==0">
        <h2>报馆基本信息(全部必填)</h2>
        <one-basic :feedback="feedback.feedback1" :amendItemFun="amendItemFun" />
      </div>
      <div v-show="current==1">
        <h2>报馆资质、附件表格以及报馆图纸(必交)</h2>
        <two-structure :feedback="feedback.feedback2" :amendItemFun="amendItemFun" />
      </div>
      <div v-show="current==2">
        <h2>缴费明细(全部必填)</h2>
        <four-other :feedback="feedback.feedback3" :amendItemFun="amendItemFun" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, onMounted, ref } from 'vue'
import { usePavilionStore } from '../../store'
import { OneBasic, TwoStructure, FourOther } from './template'
import { insertOrder, updateOrder } from '../../api'
import { message } from 'ant-design-vue'

export default defineComponent({
  components: {
    OneBasic,
    TwoStructure,
    FourOther
  },
  setup() {
    const { proxy } = getCurrentInstance()
    // console.log(usePavilionStore());
    const pavilionStore = usePavilionStore()
    const current = ref(0)
    const projectName = ref('')
    let project_id = ''
    let dataList = ref([{}, [], {}])
    let feedback = ref({ feedback1: '', feedback2: '', feedback3: '' })
    let dataArr = []
    const init = async () => {
      // 获取id userid dataid flag-为true时修改，false时新增
      // store.dispatch('resNewspaper', { p_id: 152, user_id: 18, data_id: 483, flag:true })

      // session ---- user_id
      /*
       -新增
       --session存储 project_id,
       -修改
       --session存储 data_id(id), project_id
      */
      let user_id = window.sessionStorage.getItem('user-id')
      let data_id = window.sessionStorage.getItem('id')
      let p_id = window.sessionStorage.getItem('project_id')
      // console.log(data_id)
      // 根据dataId判断新增或修改状态
      if (data_id === null) {
        // 新增
        // store.dispatch('resNewspaper', { user_id, p_id, flag:false })
        pavilionStore.resNewspaper({ user_id, p_id, flag: false })
      } else {
        // 修改
        // store.dispatch('resNewspaper', { user_id, p_id, data_id, flag:true })
        pavilionStore.resNewspaper({ user_id, p_id, data_id, flag: true })
      }
    }
    const echoList = (data) => {
      let obj1 = {}; let obj2 = []; let arr3 = []
      // for (const key in data) {
      //   if (Object.hasOwnProperty.call(data, key)&&key.indexOf('fileList') >= 0) {
      //     const element = data[key];
      //     // if (key.replace(/[^0-9]/ig,"")<=8) {
      //     obj2[key] = element
      //     // }else{
      //     //   obj3[key] = element
      //     // }
      //   }
      // }
      let { feedback1, feedback2, feedback3 } = data.order
      feedback.value = { feedback1, feedback2, feedback3 }
      obj1 = data.order
      obj2 = data.fileList
      arr3 = data.costList
      console.log(obj1, obj2, arr3)
      dataList.value = [obj1, obj2, arr3]
    }
    onMounted(init)
    const amendItemFun = (number) => {
      if (number !== 3) {
        current.value = number
      } else {
        sendNewspaper()
      }
    }
    // 上传/更细数据
    const sendNewspaper = async () => {
      const costArray = []
      const fileArray = []
      // 第三步数据处理
      pavilionStore.initTableList.data.forEach(item => {
        let { id, project_sort_name, des_id, des, price, count, yj } = item
        if (des_id) {
          costArray.push({
            id,
            name: project_sort_name,
            des_id,
            des,
            price,
            count,
            cost_money: count * price,
            yj,
            yj_money: yj * count
          })
        } else {
          costArray.push({
            id,
            name: project_sort_name,
            des,
            price,
            count,
            cost_money: count * price,
            yj,
            yj_money: yj * count
          })
        }
      })
      // 上传图片数据处理
      for (const key in pavilionStore.reviseDataUpdate) {
        if (Object.hasOwnProperty.call(pavilionStore.reviseDataUpdate, key)) {
          const element = pavilionStore.reviseDataUpdate[key]
          element.forEach((item) => {
            if (item.id) {
              fileArray.push({
                p_file_id: key,
                id: item.id,
                file_type: item.file_type,
                file_name: item.file_name,
                url: item.url
              })
            } else {
              fileArray.push({
                p_file_id: key,
                file_type: item.file_type,
                file_name: item.name,
                url: item.url
              })
            }
          })
        }
      }
      const reviseUserinfo = pavilionStore.reviseUserinfo
      const project = pavilionStore.project
      const user = pavilionStore.user
      const subtotal = pavilionStore.subtotal

      const obj = {
        id: reviseUserinfo.id,
        pid: project.id,
        aid: reviseUserinfo.aid,
        p_name: project.p_name,
        booth_name: reviseUserinfo.booth_name,
        booth_area: reviseUserinfo.booth_area,
        corp: reviseUserinfo.corp,
        corp_contacts: reviseUserinfo.corp_contacts,
        corp_contacts_mobile: reviseUserinfo.corp_contacts_mobile,
        build_corp: user.corp_name,
        build_contacts: user.name,
        build_contacts_mobile: user.mobile,
        examine: reviseUserinfo.examine,
        costs: subtotal.costs,
        deposit: subtotal.deposit,
        overdue_money: subtotal.overdue_money,
        total: subtotal.total,
        status: reviseUserinfo.status | 0,
        costArray,
        fileArray
      }

      const id = reviseUserinfo.id
      let res = {}
      if (id) {
        // 修改
        obj.id = id * 1
        res = await updateOrder(obj)
      } else {
        // 新增
        obj.id = 0
        const userID = window.sessionStorage.getItem('user-id')
        res = await insertOrder({ ...obj, user_id: userID })
      }

      message.success(res.msg)
      pavilionStore.initDataUpdate = []
      pavilionStore.user = {}
      pavilionStore.project = {}
      pavilionStore.reviseUserinfo = {}
      pavilionStore.reviseDataUpdate = {}
      pavilionStore.initTableList = {}
      pavilionStore.subtotal = {
        costs: 0, // 小计
        deposit: 0, // 押金
        overdue_money: 0, // 逾期费
        total: 0 // 合计
      }
      window.sessionStorage.removeItem('id')
      window.sessionStorage.removeItem('project_id')
      proxy.$root.$router.push({ name: 'newspaperList' })

      // return
      // let arr = []
      // // 组装第三步表格数据
      // dataArr[2].costArray.forEach(item => {
      //   let { id, project_sort_name, des_id, des, price, count, yj } = item
      //   arr.push({
      //     id,
      //     name: project_sort_name,
      //     des_id,
      //     des,
      //     price,
      //     count,
      //     cost_money: count * price,
      //     yj,
      //     yj_money: yj * count
      //   })
      // });
      // // 报馆项目id
      // let pid = window.sessionStorage.getItem('project_id')
      // // 该数据id（修改时存在）
      // let id = window.sessionStorage.getItem('id')

      // let obj = { pid: pid * 1, ...dataArr[0], ...dataArr[2] }
      // obj.overdue_money = dataArr[2].overdue_money
      // console.log(dataArr);
      // obj.fileArray = [...dataArr[1]]
      // obj.costArray = arr
      // let res = {}
      // let userID = window.sessionStorage.getItem('user-id')
      // if (id) {
      //   obj.id = id * 1
      //   res = await updateOrder(obj)
      // } else {
      //   obj.id = 0
      //   res = await insertOrder({ ...obj, user_id: userID })
      // }
      // message.success(res.msg)
      // window.sessionStorage.removeItem('id')
      // window.sessionStorage.removeItem('project_id')
      // proxy.$root.$router.push({ name: 'newspaperList' })
    }

    return {
      current,
      projectName,
      amendItemFun,
      dataList,
      feedback
    }
  }
})
</script>

<style scoped lang='less'>
h2 {
  padding: 36px;
  text-align: center;
}

.new-newspaper .ant-steps {
  padding: 20px 40px;
}

.new-newspaper button {
  width: 120px;
}
</style>
<style lang="less">
.new-newspaper .ant-form {
  max-width: 1000px;
  width: 100%;
  margin: auto;
}

.item .preview-img {
  height: 100%;
  object-fit: contain;
  border: 1px solid #d9d9d9;
  padding: 6px;
}

.item .ant-image {
  margin-left: 10px;
}

.item .ant-image:first-child {
  margin-left: 0;
}
</style>

<style lang="less">
.my-err {
  position: relative;
  padding: 20px;
  width: 600px;
  margin: 0 auto 40px;
  color: red;
  border-radius: 6px;
  box-shadow: 0 0 10px #ccc;

  img {
    position: absolute;
    top: 6px;
    right: 6px;
    height: 16px;
    width: 16px;
    overflow: hidden;
    line-height: 1;
    font-size: 28px;
    color: rgb(110, 108, 108);
    cursor: pointer;
  }
}

.my-title {
  padding-bottom: 8px;
  vertical-align: middle;
}

.my-title::before {
  content: '*';
  color: red;
  font-weight: 600;
  font-size: 16px;
  vertical-align: middle;
  margin-right: 4px;
}
</style>
